require.context("../images", true, /\.(gif|jpg|png|svg)$/i);

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import "../stylesheets/application.css";
import "../stylesheets/application.scss";

import "alpinejs";

import "../src/utils";
import "../packs/bullet";