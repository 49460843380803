document.addEventListener('turbolinks:load', () => {

  const styles = {
    backgroundColor: '#B7121F',
    color: 'white',
    position: 'fixed',
    bottom: '0',
    width: '100%',
    cursor: 'pointer',
    padding: '10px 10px',
    textAlign: 'left'
  }

  const bulletFooter = document.getElementById('bullet-footer');
  if (bulletFooter) {
    Object.assign(bulletFooter.style, styles);
  }
});
